import { ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  useTheme,
} from "@mui/material";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useState } from "react";

import { ClockActionPictureDialogEvent } from "./ClockActionPictureDialogEvent";

interface ReviewPicturePageProps {
  imageBlob?: Blob;
  imageUrl?: string;
  uploadFileName?: string;
  logData: Record<string, unknown>;

  onContinue: () => void;
  onCancel: () => void;
}

const S3_BUCKET_URL = "https://facility-timeclock-compliance-experiment-images.s3.amazonaws.com";

export function ReviewPicturePage(props: ReviewPicturePageProps) {
  const { imageBlob, imageUrl, uploadFileName, logData, onContinue, onCancel } = props;
  const theme = useTheme();

  const [isUploading, setIsUploading] = useState(false);
  const modalState = useModalState(ModalStatus.OPEN);

  const startUpload = async () => {
    if (!imageUrl || !imageBlob || !uploadFileName) {
      logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
        ...logData,
        event: ClockActionPictureDialogEvent.PICTURE_TAKING_FAILED,
        error: "No imageUrl or imageBlob in ReviewPicturePage",
        imageUrl,
        hasImageBlob: !!imageBlob,
        hasUploadFileName: !!uploadFileName,
      });

      onContinue();
      return;
    }

    try {
      const uploadStartTime = Date.now();
      setIsUploading(true);

      const formData = new FormData();
      formData.append("key", uploadFileName);
      formData.append("file", imageBlob);

      /*
        Making a "no-cors" request to S3 bucket because the bucket
        is returning a 307 redirect without CORS headers at the moment.

        Since the request is "no-cors", it returns an opaque response
        which doesn't have status code, so we don't know if the upload
        was successful and we're not handling errors.
      */
      await fetch(S3_BUCKET_URL, {
        method: "POST",
        body: formData,
        mode: "no-cors",
      });

      logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
        ...logData,
        event: ClockActionPictureDialogEvent.UPLOAD_SUCCESS,
        uploadDurationMs: Date.now() - uploadStartTime,
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : "Unknown error";

      logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EXPERIMENT_EVENT, {
        ...logData,
        event: ClockActionPictureDialogEvent.UPLOAD_FAILURE,
        error: errorMessage,
      });
    } finally {
      setIsUploading(false);
    }

    onContinue();
  };

  return (
    <Dialog fullScreen open={modalState.modalIsOpen} onClose={onCancel}>
      <DialogTitle sx={{ padding: 2 }}>Looks good?</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flexShrink: 1,
        }}
      >
        <Text textAlign="center" marginBottom={2}>
          The photo will be reviewed by Clipboard
        </Text>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          flexShrink={1}
          overflow="hidden"
        >
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Clock Action"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                borderRadius: theme.shape.borderRadius,
                objectFit: "contain",
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: "column",
        }}
      >
        <Button fullWidth variant="contained" disabled={isUploading} onClick={startUpload}>
          {isUploading ? "Uploading..." : "Looks great! Submit photo"}
        </Button>
        <Button fullWidth disabled={isUploading} onClick={onCancel}>
          Try again - go back
        </Button>
      </DialogActions>
    </Dialog>
  );
}
