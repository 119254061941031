export enum ClockActionPictureDialogEvent {
  FLOW_STARTED = "Flow Started",
  FLOW_COMPLETED = "Flow Completed",

  POLICY_ACCEPTED = "Policy Accepted",
  POLICY_CANCELLED = "Policy Cancelled",

  RETAKE_PICTURE = "Retake Picture",
  PICTURE_TAKING_FAILED = "Picture Taking Failed",

  ERROR_DIALOG_OPENED = "Error Dialog Opened",
  ERROR_DIALOG_RETRY = "Error Dialog Retry",
  ERROR_DIALOG_CANCELLED = "Error Dialog Cancelled",

  UPLOAD_SUCCESS = "Upload Success",
  UPLOAD_FAILURE = "Upload Failure",
}
