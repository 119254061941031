import { Image, Text, type UseModalState, useModalState } from "@clipboard-health/ui-react";
import { Button, Skeleton, Stack } from "@mui/material";
import { logEvent } from "@src/appV2/lib/analytics";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";
import { useCountdownTimer } from "@src/appV2/lib/utils/useCountdownTimer";
import { useQrCodeDataUrl } from "@src/appV2/lib/utils/useQrCodeDataUrl";
import { USER_EVENTS } from "@src/constants";
import { useEffect } from "react";

import { useGetShiftMutation } from "../api/useGetShiftMutation";
import {
  touchpointCodeGenerator,
  type TouchpointCodeGeneratorProps,
} from "./touchpointCodeGenerator";
import { TouchpointContinueAnywayDialog } from "./TouchpointContinueAnywayDialog";

const REFETCH_SHIFT_INTERVAL_3_SEC_IN_MS = 3000;

export function TouchpointQrAutomatedDialog(props: {
  modalState: UseModalState;
  kioskLocation: string;
  qrCodeProps: TouchpointCodeGeneratorProps;
  onCancel: () => void;
  onSuccess: () => void;
  onContinueAnyway: () => void;
}) {
  const { modalState, kioskLocation, qrCodeProps, onCancel, onSuccess, onContinueAnyway } = props;
  const { facilityName, punchType: clockAction } = qrCodeProps;
  const { shiftId, facilityId, agentId } = qrCodeProps;
  const continueAnywayModalState = useModalState();

  const {
    data: touchpointQrDataImageLink,
    isLoading: isLoadingTouchpointQrDataImage,
    isSuccess: isTouchpointQrDataImageSuccess,
  } = useQrCodeDataUrl(
    {
      data: touchpointCodeGenerator(qrCodeProps),
      qrOptions: { margin: 1, scale: 10 },
    },
    {
      enabled: modalState.modalIsOpen,
      meta: {
        userErrorMessage: "Failed to generate Touchpoint integration QR code",
      },
    }
  );

  const { secondsLeft: secondsLeftForNotWorking, startTimer: startNotWorkingTimer } =
    useCountdownTimer({ durationInSeconds: 60 });

  const { mutateAsync: refetchShiftDetails } = useGetShiftMutation(shiftId, {
    onSuccess: (shiftDetails) => {
      if (!modalState.modalIsOpen) {
        return;
      }

      if (
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        (clockAction === "clock-in" && shiftDetails?.response?.clockInOut?.start) ||
        (clockAction === "clock-out" && shiftDetails?.response?.clockInOut?.end)
      ) {
        logEvent(USER_EVENTS.TOUCHPOINT_SCAN_SUCCESS, {
          shiftId,
          facilityId,
          workerId: agentId,
          action: clockAction,
        });
        onSuccess();
        return;
      }

      // Retry again in 3 seconds if the shift is not clocked in yet
      setTimeout(async () => {
        await refetchShiftDetails(undefined);
      }, REFETCH_SHIFT_INTERVAL_3_SEC_IN_MS);
    },
  });

  useEffect(() => {
    let timeoutRef: NodeJS.Timeout | undefined;
    if (modalState.modalIsOpen) {
      startNotWorkingTimer();
      timeoutRef = setTimeout(async () => {
        await refetchShiftDetails(undefined);
      }, REFETCH_SHIFT_INTERVAL_3_SEC_IN_MS);
    }

    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [startNotWorkingTimer, refetchShiftDetails, modalState.modalIsOpen]);

  return (
    <>
      <CommonDialog
        disableActionsSpacing
        modalState={modalState}
        scroll="body"
        actions={
          <>
            <Button
              fullWidth
              variant="outlined"
              disabled={secondsLeftForNotWorking > 0}
              color="primary"
              onClick={() => {
                continueAnywayModalState.openModal();
              }}
            >
              Kiosk is not working
            </Button>
            <Button fullWidth variant="text" onClick={onCancel}>
              Go back
            </Button>
          </>
        }
        title="Scan QR code at kiosk"
        onClose={() => {
          onCancel();
        }}
      >
        <Stack spacing={2} alignItems="center">
          <Text>
            Clock {clockAction === "clock-in" ? "in" : "out"} by scanning the QR code at the kiosk
            located {kioskLocation}.
          </Text>
          {isLoadingTouchpointQrDataImage && (
            <Skeleton variant="rectangular" width="100%" height="300px" />
          )}
          {isTouchpointQrDataImageSuccess && (
            <Image
              src={touchpointQrDataImageLink}
              alt="Touchpoint QR Code"
              width="60%"
              maxWidth="50vh"
            />
          )}
          {!isLoadingTouchpointQrDataImage && !isTouchpointQrDataImageSuccess && (
            <Text>Error generating QR. Please close this popup and try again.</Text>
          )}
          <Text
            width="100%"
            // Only hide the text to prevent layout shift
            visibility={secondsLeftForNotWorking > 0 ? "visible" : "hidden"}
            // Needed to alleviate the empty space after the text is hidden
            sx={{ lineHeight: "0.5rem" }}
          >
            Having problems? Proceed in {secondsLeftForNotWorking}s.
          </Text>
        </Stack>
      </CommonDialog>
      <TouchpointContinueAnywayDialog
        modalState={continueAnywayModalState}
        facilityName={facilityName ?? "The facility"}
        clockAction={clockAction}
        onCancel={() => {
          continueAnywayModalState.closeModal();
        }}
        onContinue={() => {
          logEvent(USER_EVENTS.TOUCHPOINT_SCAN_FAILED, {
            shiftId,
            facilityId,
            workerId: agentId,
            action: clockAction,
          });

          continueAnywayModalState.closeModal();
          onContinueAnyway();
        }}
      />
    </>
  );
}
