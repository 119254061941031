import { ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { Button } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

interface SuccessDialogProps {
  onContinue: () => void;
}

export function NfcSuccessDialog(props: SuccessDialogProps) {
  const { onContinue } = props;
  const modalState = useModalState(ModalStatus.OPEN);

  return (
    <CommonDialog
      modalState={modalState}
      title="Timeclock photo submitted"
      actions={
        <Button fullWidth variant="contained" onClick={onContinue}>
          Begin NFC scan
        </Button>
      }
      onClose={onContinue}
    >
      <Text>
        Now that you’ve submitted a photo of yourself clocking in/out with the facility’s time
        clock, you can clock in/out using the NFC time clock poster. You must use the NFC time clock
        poster to get paid accurately.{" "}
      </Text>
    </CommonDialog>
  );
}
